import styles from "./filters.module.scss";
import Button from "../button/button";
import {PortableText} from '@portabletext/react';
import myPortableTextComponents from "../utilities/portableTextStyle";
import {gsap, ScrollTrigger} from '../utilities/gsap';
import { getFile } from "@sanity/asset-utils";
import sanityProject from "../utilities/sanityProject";
import {useEffect, useRef} from "react";

export default function Filters({ content }) {

    // gsap animations
    gsap.registerPlugin(ScrollTrigger);
    const blockRef = useRef();
  
    useEffect(() => {

      // button animation on scroll
      let blockQ = gsap.utils.selector(blockRef);
      let buttons = blockQ("button");
      gsap.set(buttons, {y: 30, autoAlpha: 0});

      ScrollTrigger.batch(buttons, {
        onEnter: (elements, triggers) => {
          gsap.to(elements, {autoAlpha: 1, y: 0, stagger: 0.15, duration: .2});
        },
        start: "top bottom+=100",
        scrub: false,
      });
  
    }, []);

    const filterBtns = useRef([]);

    function toggleVideo(index) {

      let targetBtn = filterBtns.current[index];
      let targetVideo = targetBtn.getAttribute("data-video");

      let pageVideoBG = document.getElementById("pageVideoBG");
      let videoEl = pageVideoBG.querySelector("video");
      videoEl.style.opacity = 0;
      setTimeout(function() {
        let videoElSource = videoEl.querySelector("source");
        videoElSource.setAttribute("src", targetVideo);
        videoEl.load();
        videoEl.play();
        videoEl.style.opacity = 1;
      }, 500);

    }

  return (
    <div className={styles.wrapper} ref={blockRef}>
      <div className={styles.header}>
        <h1>{content.headline}</h1>
      </div>
      <div className={styles.buttons}>
        {content.buttons.map((button, index) => {

          let videoFile = getFile(button.video_file, sanityProject);
          return (
            <div key={index} className={styles.button} data-video={videoFile.asset.url} ref={el => filterBtns.current[index] = el} onMouseEnter={() => toggleVideo(index)}>
              <Button
                label={button.label}
                backgroundColor="transparent"
                color="white"
                borderColor="white"
                size="large"
              />
            </div>
          )
        })}
      </div>
      <div className={styles.subText}>
          <PortableText
            value={content.text}
            components={myPortableTextComponents}
          />
      </div>
    </div>
  );
}
