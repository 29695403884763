import Image from "next/image";
import Link from "next/link";
import styles from "./rewards.module.scss";
import Visa from "../../public/images/VISA.png";
import ThermometerBG from "../../public/images/thermometer_bg.png";
import ThermometerFG from "../../public/images/thermometer_fg.png";
import RewardsHeader from "../../public/images/loop-rewards-header.png";
import Button from "../../components/button/button";
import {PortableText} from '@portabletext/react';
import myPortableTextComponents from "../utilities/portableTextStyle";
import {gsap, ScrollTrigger} from '../utilities/gsap';
import {useEffect, useRef} from "react"

export default function Rewards({reference, modalClick, content}) {

    // gsap animations
    const cardRef = useRef();
    const thermRef = useRef();
  
    useEffect(() => {

      gsap.registerPlugin(ScrollTrigger);
  
      // animation on scroll
      gsap.set(cardRef.current, {y: 150});
      gsap.to(cardRef.current,  {
          y: 0,
          duration: .5,
          scrollTrigger: {
              trigger: reference.current,
              start: "top center",
              scrub: false,
              markers: false,
          }
      })

      if (innerWidth > 896) {
        gsap.to(thermRef.current, {
          "clip-path": "inset(0% 0% 0% 0%)",
          duration: 2,
          scrollTrigger: {
            trigger: reference.current,
            start: "top center",
            scrub: false,
            markers: false,
          }
        })
      }
  
    }, [reference]);

  return (
    <div className={styles.rewardsWrapper} ref={reference}>
      <div className={styles.card}>
        <div className={styles.cardOverlay}>
          <div className={styles.cardLeft}>
            <div className={styles.headerImage}>
              <Image
                src={RewardsHeader}
                alt="loop rewards"
              />
            </div>
            <div className={styles.subText}>
              <PortableText
                value={content.text}
                components={myPortableTextComponents}
              />
              <div>
                <Link href="/registration/step1">
                  <a>
                    <Button
                      label="sign up for free"
                    />
                  </a>
                </Link>
              </div>

            </div>
          </div>
          <div className={styles.cardRight}>
            <div className={styles.imgwrap}>
              <Image
                src={ThermometerBG}
                alt="Thermometer Background"
              />
              <div className={styles.fg}>
                <div className={styles.inner} ref={thermRef}>
                  <Image
                    src={ThermometerFG}
                    alt="Thermometer Background"
                  />
                </div>
              </div>
            </div>

          </div>
        </div>
        <div className={styles.cardUnderlay}></div>
      </div>
      <div className={styles.visaCard} ref={cardRef}>
        <div className={styles.imgwrap}>
          <Image
            src={Visa}
            alt="Visa Prepaid Card"
          />
        </div>

      </div>
    </div>
  );
}
