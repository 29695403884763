import Image from "next/image";
import Link from "next/link";
import styles from "./signage.module.scss";
import Button from "../../components/button/button";
import TVFrame from "../../public/images/TV_frame_v3.png";
import { PortableText } from "@portabletext/react";
import myPortableTextComponents from "../utilities/portableTextStyle";
import SanityImage from "../../components/utilities/sanityImage";
import { getFile } from "@sanity/asset-utils";
import sanityProject from "../utilities/sanityProject";
import { gsap, ScrollTrigger } from "../utilities/gsap";
import { useRef, useEffect } from "react";

export default function Signage({ content, modalClick }) {
  let videoFile = null;
  if (content.video_file) {
    videoFile = getFile(content.video_file, sanityProject);
  }

  const blockRef = useRef();
  const signageRef = useRef();

  useEffect(() => {

    // gsap animations
    gsap.registerPlugin(ScrollTrigger);

    // signage animation on scroll
    gsap.set(signageRef.current, { y: 100 });
    gsap.to(signageRef.current, {
      y: -50,
      scrollTrigger: {
        trigger: blockRef.current,
        start: "top bottom",
        end: "bottom top",
        scrub: 1,
        markers: false,
      },
    });
  }, []);

  return (
    <div className={styles.wrapper} ref={blockRef}>
      <div className={styles.tvWrapper} ref={signageRef}>
        <div className={styles.tvFrame}>
          <div className={styles.frameHold}>
            <Image
              src={TVFrame}
              alt="TV Frame Image"
            />
          </div>
          <div className={styles.tvBG}>
            <div className={styles.mediawrap}>
              {videoFile == null ? (
                <SanityImage image={content.image} alt="Signage Image" />
              ) : (
                <video autoPlay loop playsInline muted controls>
                  <source src={videoFile.asset.url} />
                </video>
              )}
            </div>
          </div>
        </div>

        <div className={styles.tvGradient}></div>
      </div>
      <div className={styles.colWrapper}>
        <div className={styles.header}>
          <h1>{content.headline}</h1>
        </div>
        <div className={styles.subText}>
          <PortableText
            value={content.text}
            components={myPortableTextComponents}
          />
        </div>
        <div className={styles.button}>
          <Link href="/registration/step1">
            <a>
              <Button label="sign up for free" />
            </a>
          </Link>          
        </div>
      </div>
    </div>
  );
}
