import Image from "next/image";
import styles from "./hand.module.scss";
import Button from "../button/button.js";
import HandImg from "../../public/images/hand.png";
import LoopPlayer from "../../public/images/Loop_Player_middle_fixed.png";
import {PortableText} from '@portabletext/react';
import myPortableTextComponents from "../utilities/portableTextStyle";
import {gsap, ScrollTrigger} from '../utilities/gsap';
import {useEffect, useRef} from "react"
import Link from 'next/link';

export default function Hand({content, modalClick}) {

  const blockRef = useRef();
  const handRef = useRef();
  const playerRef = useRef();

  useEffect(() => {

    if (innerWidth > 896) {

      // gsap animations
      gsap.registerPlugin(ScrollTrigger);

      // signage animation on scroll
      gsap.set(handRef.current, {y: 100});
      gsap.set(playerRef.current, {y: -350});
      gsap.to(handRef.current,  {
          y: -50,
          scrollTrigger: {
              trigger: blockRef.current,
              start: "top bottom",
              end: "bottom top",
              scrub: 1,
              markers: false,
          }
      })

      gsap.to(playerRef.current,  {
        y: 0,
        scrollTrigger: {
            trigger: blockRef.current,
            start: "top bottom",
            end: "bottom center",
            scrub: 1,
            markers: false,
        }
      })
    }

  }, []);

  return (
    <div className={styles.wrapper} ref={blockRef}>
      <div className={styles.buttonWrapper}>
        <Link href="/registration/step1">
          <a>
            <Button label="get your free player" />
          </a>
        </Link>        
      </div>
      <div className={styles.text}>
        <PortableText
          value={content.text}
          components={myPortableTextComponents}
        />
      </div>
      <div className={styles.imageWrapper}>
        <div className={styles.hand}>
          <div className={styles.imgwrap} ref={handRef}>
            <Image
              src={HandImg}
              alt="Hand Background Image"
            />
          </div>
          <div className={styles.loopPlayer} ref={playerRef}>
            <Image
              src={LoopPlayer}
              layout="fill"
              objectFit="contain"
              alt="Background Image"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
